@charset "utf-8";



/** VARIABLES **/
// $link-color: #6b11ff;
// $masthead-link-color: white;

// disable animations:
$intro-transition : none;
$global-transition : none;
$right-sidebar-width-narrow: 260px !default;
$right-sidebar-width: 300px !default;
$right-sidebar-width-wide: 400px !default;


/** MINIMAL MISTAKES IMPORTS **/
@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials



/** CUSTOM CSS **/
.masthead {
    background: #134611;
    background-image: linear-gradient(130deg, #190E4F, #CA054D); // #CA054D, DF57BC
    color: white;

    nav {
        background: none;
    }

    a {
        color: white;
    }

    a:hover {
        color: white !important;
    }

    .greedy-nav a:hover {
        color: white !important;
    }
}

.greedy-nav .hidden-links {
    background: #134611;
    background-image: linear-gradient(15deg, #190E4F, #CA054D); // #CA054D, DF57BC
}

.greedy-nav__toggle {
    // background-color: rgba(255, 255, 255, 0.2) !important;
    .navicon {
        background-color: rgba(255, 255, 255, 0.7) !important;
        &:before, &:after {
            background-color: rgba(255, 255, 255, 0.7) !important;
        }
    }
}

.search__toggle {
    color: white !important;
}

.search__toggle:hover {
    color: white !important;
}

.sidebar:hover {
    opacity: 1 !important;
}

@media (min-width: 64em) {
    .sidebar {
        opacity: 1 !important;
    }
}

article img {
    border: 1px solid #aaa;
    box-sizing: border-box;
    -webkit-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
}

.greedy-nav .visible-links a:before {
    background: rgba(255, 255, 255, 0.15);
}

.author__avatar {
    width: 100%;
    height: auto;
    display: block;

    img {
        display: block;
        border-radius: 50%;
        padding: 0 !important;
        max-width: 55% !important;
        border: 0 !important;
        margin: 0 auto;
        margin-bottom: 1em;

        @include breakpoint($small) {
            border-radius: 0 !important;
            max-width: 100% !important;
        }
    }
}

.author__bio {
    a {
        color: #52adc8;
        text-decoration: underline;
        @include breakpoint($small) {
            text-decoration: none;
        }
    }

    a:visited {
        color: #7dc2d6;
    }

    a:hover {
        text-decoration: none;
        @include breakpoint($small) {
            text-decoration: underline;
        }
    }

    ul {
        padding-inline-start: 25px;
    }
}

.author__urls-wrapper {
    display: block;
    padding-top: 1em;
    padding-left: 15px;
    padding-right: 25px;
    @include breakpoint($small) {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    button {
        display: none;
    }

    .author__urls {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        box-shadow: none;

        li {
            display: inline-block;
            padding-right: 1em;

            @include breakpoint($small) {
                padding-right: 0;
                display: block;
            }

        }

        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }
}

.page__content h2:first-child {
    margin-top: 0;
}

.page__footer-follow {
    display: none;
}

.sidebar {
    display: none;
    @include breakpoint($small) {
        display: block;
    }
}

// @media (min-width: 64em) {
//     .author__avatar {
//         width: auto;
//         height: auto;
//     }
// }
